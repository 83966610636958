import React from 'react'
import { UserSystem } from '../sections/@dashboard/UserSystem'







const UserSystemPage =() => (

<>

<UserSystem/>
</>


)


export default UserSystemPage